<template>
  <div class="my-qas app-my-wrap">
    <div class="app-my-title">
      お問い合わせ
    </div>
    <div class="app-my-content">
      <el-form ref="newQaForm" :model="form" class="app-form" :rules="rules">
        <el-form-item label="お問い合わせ項目" prop="type">
          <el-radio v-model="form.type" label="0">講座について</el-radio>
          <el-radio v-model="form.type" label="1">サービスなど</el-radio>
        </el-form-item>
        <el-form-item label="お問い合わせ件名" prop="title">
          <el-input v-model="form.title" autocomplete="off"></el-input>
        </el-form-item>
        <div class="my-qas-table-text">
          <div>※講座についてお問い合わせの場合、講座ID・講座名を記載する上でお問い合わせください。</div>
          <div>※サービスについてのお問い合わせの例は以下です。ご参照ください。</div>
          <div class="my-qas-indent">会員登録・ログインについて</div>
          <div class="my-qas-indent">機能要望</div>
          <div class="my-qas-indent">不具合の報告</div>
          <div class="my-qas-indent">退会したい</div>
          <div>※上記以外の場合、その他を選択ください。</div>
        </div>
        <el-form-item label="お問い合わせ内容" prop="content">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 10}"
            v-model="form.content"
          >
          </el-input>
        </el-form-item>
        <div class="my-qas-button" @click="submit">
          送信する
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request';
import { Notification } from 'element-ui';
export default {
  data() {
    return {
      form: {
        type: '0',
        title: '',
        content: '',
      },
      rules: {
        title: [
          { required: true, message: '必須です。', trigger: 'blur' },
          { max: 50, message: '50文字以内を入力ください。', trigger: 'blur' },
        ],
        content: [
          { required: true, message: '必須です。', trigger: 'blur' },
          { max: 1000, message: '1000文字以内を入力ください。', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    submit() {
      this.$refs.newQaForm.validate((valid) => {
            //         this.$router.push({
            //   path: '/mypage/qasuccess',
            // }).catch(() => {});
        if (valid) {
          request('inquirySendMail', this.form).then((res) => {
            Notification({
              title: '成功',
              message: '問い合わせの送信が成功しました。',
              // duration: 0,
              offset: 75,
              type: 'success',
            });
            this.$router.push({
              path: '/mypage/qasuccess',
            }).catch(() => {});
          });
        }
        console.log('error submit!!');
        return false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.my-qas {
  .app-form {
    .my-qas-table-text {
      div {
        line-height: 30px;
      }
      .my-qas-indent {
        margin-left: 20px;
      }
    }
    .my-qas-button {
      background-color: #F1DCDB;
      padding: 15px;
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 18px;
      cursor: pointer;
    }
  }
}
</style>
